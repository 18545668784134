const Footer = () => {

    return (
        <>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div align='center'>
            <a href='https://www.free-website-hit-counter.com'>
                <img src='https://www.free-website-hit-counter.com/c.php?d=9&id=152946&s=5' border='0' alt='Free Website Hit Counter' />
            </a><br />
            <small>
            <a href='https://www.free-website-hit-counter.com' title="Free Website Hit Counter">Free website hit counter
            </a></small>
        </div></>
     );
}

export default Footer;