const About = () => {
  return (
    <div className="about">
      <h2>About Us</h2>
      <br></br>
      We are located between Smithville and Platte City Missouri. Just about 15 minutes from KCI. We raise commercial cattle and bulls as well as seedstock cattle and bulls. The owner has been working with cattle nearly all his life, with the exception of college, and is a 3rd generation cattleman. We chose Angus as our breed of choice due to docility, and calving ease. Some of our bulls and cows are so naturally docile we can pet them in the pasture! We breed to some of the finest angus bloodlines available, while concentrating on calving eased yet growthy calves.  Because we are a small, humble ranch, each of our bulls is given proper nutrition and care to ensure each one is a product we would want to buy. We work hard to keep our overhead costs low, and that is why we can sell our bulls at much less than you would pay at an auction.
      </div>
  );
}

export default About;