const Contact= () => {
  return (
    <div className="contact">
      <h2>Contact Us</h2>
      <br></br>
      <div>Fox Angus Ranch</div>
      <div>Platte City, Mo</div>
      <div>
        <a href="tel:816-277-4968">Mobile: 816-277-4968 (Calls or Text)</a>
      </div>
      <div>
        <a href="tel:816-873-3531">Office: 816-873-3531</a>
      </div>
      <div><a href="mailto:farmfreshcattle@hotmail.com">farmfreshcattle@hotmail.com</a></div>      
      </div>
  );
}

export default Contact;