const Home = () => {

    return ( 
        <div className="home">
            <h2>Current Inventory</h2>
            <br></br>
            We have one 2 year old Registered Black Angus bull that will be looking for a new home. <br></br><br></br>
            Please give us a call at 816-277-4968 if you are interested. <br></br><br></br>

            More calves expected this fall!
        </div>   
     );
}
 
export default Home;